.ant-layout-header {
  border-radius: 0px 0px 50px 50px;
  /* border-radius: 0px 0px 20px 20px; */
  position: fixed;
  width: 100%;
  z-index: 100;

  box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
}


.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  box-shadow: 0px 0px 15px 0 rgba(24 ,144 ,255 ,.4);
}

/* .ant-menu-dark.ant-media-horizontal > .ant-menu-item:hover {
  box-shadow: 0px 0px 15px 0 rgba(24 ,144 ,255 ,1) !important;
} */

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover { 
  transition: width 10s !important;
  /* box-shadow: 0px 0px 15px 0 rgba(24 ,144 ,255 ,1) !important; */
}