.loginFormContainer {
  display: table;
  margin: 0 auto;
  margin-top: 20vh;
  padding-top: 20px;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  min-height: 200px;
  max-width: 550px;

  width: 100%;

  margin: 100px auto;
  background: #fff;
  border-radius: 15px;
}

.loginFormTitle {
  text-align: center;
  padding-bottom: 10px;
}

.loginScreen {
  background-color: #f0f2f5;
  min-height: 100vh;
}
