.incomeDescription {
  text-align: left;
  padding-left: 40px;
}
.incomeValue {
  text-align: right;
  padding-right: 40px;
}

.modelNameModal {
  font-size: large;
  font-weight: 400;
}

.modalDivider {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.financialModalTextArea { 
  /* margin-top: 20px; */
  border-radius: 10px !important;
  min-height: 250px !important;
}

.financialModalTabs > .ant-tabs-nav {
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}