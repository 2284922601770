.site-layout-content {
  /* min-height: 280px !important; */
  margin-top: 20px !important;
  background: #fff !important;
  border-radius: 20px !important;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px !important;
  height: 31px !important;
  margin: 16px 24px 16px 0 !important;
  background: rgba(255, 255, 255, 0.3) !important;
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right !important;
  margin: 16px 0 16px 24px !important;
}

.layout {
  min-height: 100vh !important;
  padding-bottom: 100px !important;
}

.ant-table-pagination {
  padding-right: 20px !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}

html {
  background-color: #001529 !important;
}

.ant-menu-item {
  margin-right: 10px !important;
}

.ant-modal-content {
  border-radius: 20px !important;
}
.ant-modal-header {
  border-radius: 20px 20px 0px 0px !important;
}
